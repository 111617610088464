<template>
  <div class="home-container">
    <div class="carousel-container">
      <Carousel :carouselData="homeCarData" />
    </div>
    <div class="company-profile global-container">
      <div class="development-title">
        <GenericTitle
          :chineseText="IntroduChinese"
          :englishText="IntroduEnglish"
        />
      </div>
      <div class="brief-image">
        <img :src="baseUrl + 'home/Group 518@2x.png'" alt="Image" />
      </div>
    </div>
    <div class="experience-container">
      <div
        class="experience-item"
        v-for="(exp, index) in aboutData"
        :key="index"
      >
        <div class="num">{{ exp.num }}</div>
        <div class="title">{{ exp.title }}</div>
      </div>
    </div>
    <div class="physical-container">
      <testBnner />
    </div>
    <div class="development-container">
      <div class="development-title" >
        <GenericTitle
          :chineseText="develuChinese"
          :englishText="develuEnglish"
        />
      </div>
      <div class="development-time">
        <div
          class="itme"
          v-for="(dev, index) in historyData"
          :key="index"
          :style="boxStyle(index)"
        >
          <div class="date">{{ dev.date }}</div>
          <div class="title">{{ dev.title }}</div>
        </div>
      </div>
    </div>
    <div class="products-container">
      <div class="development-title" :style="{ marginTop: '100px' }">
        <GenericTitle :chineseText="prdChinese" :englishText="prdEnglish" />
      </div>
      <div class="introduce-item">
        <div
          class="introduce-container"
          v-for="(item, index) in introductionData"
          :key="index"
          :style="{ backgroundImage: `url(${item.image})` }"
        >
          <h2>{{ item.describe.title }}</h2>
          <p>{{ item.describe.introduction }}</p>
        </div>
      </div>
    </div>
    <div class="banner-container">
      <p>携手· 市面 首款 智能鱼缸</p>
      <p>同创· 水族 智能 领导品牌</p>
    </div>
    <div class="footerInfo-contact">
      <FooterInfo />
    </div>
  </div>
</template>
<script setup lang="ts" name="Home">
import Carousel from "../components/Carousel.vue";
import FooterInfo from "../components/FooterInfo.vue";
import testBnner from "../components/testBnner.vue";
import GenericTitle from "../components/GenericTitle.vue";
import { reactive, ref , getCurrentInstance} from "vue";
const instance = getCurrentInstance();
const baseUrl = instance?.appContext.config.globalProperties.$baseUrl;
const homeCarData = reactive([
  {
    image: baseUrl + "home/Maskgroup@1.png",
    altText: "Image1",
    describe: {
      title: "蓝得智能水族",
      introduction:
          "蓝得智能水族，智慧引领水族新时代！创新科技，卓越养殖体验。选择蓝得，与智能共创水族新篇章！",
    },
  },
  {
    image: baseUrl + "home/Maskgroup@2.png",
    altText: "Image2",
    describe: {
      title: "蓝得智慧鱼池",
      introduction:
          "蓝得智能鱼池是一款以科技创新为驱动，致力于为消费者提供智能化、便捷化的鱼池养护体验的高科技产品。",
    },
  },
  {
    image: baseUrl + "home/Group632@3x.png",
    altText: "Image3",
    describe: {
      title: "蓝得智慧配件",
      introduction:
          "以研发制造智能养护系统为主的科技创新企业，致力于为客户打造极致的轻松养鱼体验，实现水族、宠物及人类辅助养护的智慧未来。",
    },
  },
]);
const introductionData = reactive([
  {
    image: baseUrl + "home/products1.png", // 替换为baseUrl路径
    describe: {
      title: "智能化科技",
      introduction:
          "依托智能系统、创新技术等开发， 打造轻松科学养鱼的产品， 彻底解决客户痛点",
    },
  },
  {
    image: baseUrl + "home/products2.png", // 替换为baseUrl路径
    describe: {
      title: "一站式生态",
      introduction: "共建水族行业的全生态资源， 赋能轻松愉悦养鱼的一站式服务",
    },
  },
  {
    image: baseUrl + "home/products3.png", // 替换为baseUrl路径
    describe: {
      title: "大数据服务",
      introduction:
          "推进水族行业大数据， 以云计算提供市场分析、精准需求和定向开发， 及营销活动",
    },
  },
]);
const aboutData = ref([
  {
    num: "5",
    title: "自营体验中心",
  },
  {
    num: "15",
    title: "官方经销商",
  },
  {
    num: "200",
    title: "个人代理商",
  },
  {
    num: "100,000+",
    title: "注册用户",
  },
]);
const historyData = ref([
  {
    date: "2020.08",
    title: "项目启动",
  },
  {
    date: "2021.01",
    title: "公司成立",
  },
  {
    date: "2021.05",
    title: "智能养护系统验证",
  },
  {
    date: "2022.03",
    title: "首批客户交付",
  },
  {
    date: "2022.10",
    title: "云计算管理系统",
  },
  {
    date: "2023.06",
    title: "天使轮投资",
  },
  {
    date: "2024.01",
    title: "受华为全屋智能鸿蒙生态 认证",
  },
]);
const boxStyle = (index: number) => {
  return {
    borderRight:
      (index + 1) % 4 === 0 || index === historyData.value.length - 1
        ? "none"
        : "2px solid #e4e6e8",
  };
};
const IntroduChinese = "企业简介";
const IntroduEnglish = "About us";
const develuChinese = "发展历程";
const develuEnglish = "History";
const prdChinese = "产品与服务";
const prdEnglish = "Introduction";
</script>
<style scoped lang="scss">
.home-container {
  background-color: #f7f7f7;
  overflow: hidden;

  .carousel-container {
    width: 100%;
    height: 696px;
    margin-top: 60px;
  }
  .company-profile {
    width: 1200px;
    height: 420px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    margin-top: 90px;
    background-color: #f5f8fb;
    padding-bottom: 100px;
    .brief-introduction {
      width: 150px;
      height: 120;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      text-align: center;
      font-family: Source Han Sans CN, Source Han Sans CN;
    }
    .brief-image {
      width: 100%;
      height: 312px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .experience-container {
    width: 100%;
    height: 198px;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 200px;

    .experience-item {
      width: 120px;
      height: 114px;
      text-align: left;
      transform: translateX(-60px);
      .num {
        width: 36px;
        height: 74px;
        font-family: OPPOSans, OPPOSans;
        font-weight: 500;
        font-size: 56px;
        color: #333333;
        line-height: 66px;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }
      .title {
        width: 120px;
        height: 30px;
        font-family: Source Han Sans CN, Source Han Sans CN;
        font-weight: 400;
        font-size: 20px;
        color: #333333;
        line-height: 23px;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }
    }
  }
  .physical-container {
    width: 100%;
    height: 760px;
  }
  .development-container {
    width: 100%;
    height: 732px;
    background-image: url("../assets/home/development.png");
    background-size: cover;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    .development-title {
      margin-top: 100px;
      display: inline-block;
    }
    .development-time {
      width: 1197px;
      height: 549px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      .itme {
        width: 278px;
        height: 176px;
        border-right: 2px solid #e4e6e8;
        gap: 200px;

        .date {
          width: 140px;
          height: 50px;
          font-family: PingFang SC, PingFang SC;
          font-weight: bold;
          font-size: 36px;
          color: #333333;
          line-height: 42px;
          text-align: left;
          margin-left: 40px;
        }
        .title {
          width: 240px;
          height: 30px;
          font-family: Source Han Sans CN, Source Han Sans CN;
          font-weight: 400;
          font-size: 20px;
          color: #606163;
          line-height: 23px;
          text-align: left;
          margin-top: 32px;
          margin-left: 40px;
        }
      }
      .no-border-right {
        border-right: none;
      }
    }
  }
  .products-container {
    width: 100%;
    height: 762px;
    background: #ffffff;
    display: flex;
    align-items: center;
    flex-direction: column;
    font-family: Source Han Sans CN, Source Han Sans CN;
    .products-title {
      margin-top: 100px;
    }
    .introduction-title {
      margin-top: 12px;
    }
    .introduce-item {
      display: flex;
      justify-content: center;
      gap: 28px;
      .introduce-container {
        width: 380px;
        height: 420px;
        background-size: cover;
        margin-top: 40px;
        h2 {
          width: 120px;
          height: 36px;
          font-weight: bold;
          font-size: 24px;
          color: #333333;
          text-align: left;
          margin-left: 28px;
        }
        p {
          width: 320px;
          height: 90px;
          font-weight: 400;
          font-size: 20px;
          color: #606163;
          line-height: 30px;
          text-align: left;
          margin-left: 28px;
        }
      }
    }
  }
  .banner-container {
    width: 100%;
    height: 250px;
    background-image: url("../assets/home/Maskgroup@3x.png");
    background-size: cover;
    font-family: Source Han Sans CN, Source Han Sans CN;
    font-size: 32px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 190px;
  }
  .footerInfo-contact {
    width: 100%;
    height: 440px;
  }
}
</style>
