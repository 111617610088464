<template>
  <div class="footerInfo-container">
    <div class="introduce-container global-container">
      <div class="introduce-left">
        <img class="image" src="../assets/home/Group 408@2x.png" />
        <div class="address">
          地址：广州市番禺区华腾路华创产业园总部西塔3B11楼01
        </div>
        <div class="iphone">电话：13326445858</div>
        <div class="qr-code">
          <div class="item">
            <img class="image-qr" :src="baseUrl + 'home/qr1.png'" />
            <p>蓝得微信公众号</p>
          </div>
          <div class="item">
            <img class="image-qr" :src="baseUrl + 'home/qr2.png'" />
            <p>蓝得管家微信号</p>
          </div>
        </div>
      </div>
      <div class="introduce-right">
        <div class="item" v-for="item in introduceData" :key="item.title">
          <h2>{{ item.title }}</h2>
          <ul>
            <li v-for="section in item.sections" :key="section">
              {{ section }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="copyright-container global-container">
      <div class="horizontal-line"></div>
      <div class="copyright">
        Copyright © 2020-2024 广州市蓝得生命科技有限公司 版权所有
      </div>
      <div class="filings">粤公网安备 44030502008138号</div>
    </div>
  </div>
</template>
<script setup lang="ts" name="FooterInfo">
import { reactive,getCurrentInstance } from "vue";
const instance = getCurrentInstance();
const baseUrl = instance?.appContext.config.globalProperties.$baseUrl;
const introduceData = reactive([
  {
    title: "首页",
    sections: ["企业简介", "发展历程", "产品与服务"],
  },
  {
    title: "产品介绍",
    sections: ["鱼缸", "鱼池", "配件"],
  },
  {
    title: "数字化平台",
    sections: ["大数据分析", "私域管理", "精准服务"],
  },
  {
    title: "定制化服务",
    sections: [
      "服务内容",
      "部分案例",
      "定制流程",
      "服务优势",
      "客户反馈和推荐",
    ],
  },
   {
    title: "联系我们",
    sections: [
      "公司地址",
      "加入我们",
      "合作联系",
    
    ],
  },
]);
</script>

<style scoped lang="scss">
.footerInfo-container {
  width: 100%;
  height: 100%;
  background: #f2f5fa;
  font-family: Source Han Sans CN, Source Han Sans CN;
  .introduce-container {
    height: 262px;
    // background-color: yellow;
    padding-top: 64px;
    display: flex;
    justify-content: space-between;
    .introduce-left {
      width: 359px;
      height: 100%;
      //   background-color: #858c99;
      font-weight: 400;
      .image {
        width: 150px;
        height: 38px;
      }
      .address {
        display: inline-block;
        font-size: 14px;
        color: #858c99;
        line-height: 22px;
        margin-top: 28px;
      }
      .iphone {
        display: inline-block;
        font-size: 14px;
        color: #858c99;
        line-height: 22px;
        margin-top: 8px;
      }
      .qr-code {
        width: 244px;
        height: 116px;
        margin-top: 25px;
        display: flex;
        justify-content: space-between;

        .item {
          height: 116px;
          width: 98px;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          align-items: center;
          
          .image-qr {
            width: 90px;
            height: 90px;
          }
          p {
            display: inline-block;
            font-size: 14px;
            color: #858c99;
          }
        }
      }
    }
    .introduce-right {
      width: 696px;
      height: 100%;
      display: flex;
      justify-content: space-between;

      .item {
        // display: inline-block;
          display: flex;
          flex-direction: column;
          text-align: left;
        
        h2 {
          font-size: 16px;
          color: #333333;
          line-height: 22px;
          text-align: left;
       
        }
        ul li {
          font-size: 14px;
          color: #858c99;
          line-height: 22px;
       
          margin-top: 10px;
        }
      }
    }
  }
  .copyright-container {
    height: 94px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 28px;

    .horizontal-line {
      width: 100%;
      border: 1px solid #dcdfe5;
    }
    .copyright {
      display: inline-block;
      margin-top: 20px;
      font-size: 16px;
      color: #858c99;
      line-height: 22px;
      text-align: center;
    }
    .filings {
      display: inline-block;
      font-size: 16px;
      color: #858c99;
      line-height: 22px;
      margin-top: 10px;
    }
  }
}
</style>
