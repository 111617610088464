import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'


const app = createApp(App);

// 设置全局路径
app.config.globalProperties.$baseUrl = "https://fish-landlt-dev.oss-cn-shenzhen.aliyuncs.com/landle-tech-website/assets/";

app.use(router);
app.use(store);
app.mount('#app');