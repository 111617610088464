<template>
  <div class="tab-bar">
    <router-link
      v-for="tab in tabs"
      :to="tab.link"
      :key="tab.link"
      :class="['tab-link', { active: currentTab === tab.link }]"
      @click="handleTabClick(tab.link)"
      >{{ tab.label }}</router-link
    >
  </div>
</template>
  
  <script setup lang="ts" name="TabBar">
import { ref } from "vue";
const currentTab = ref("/");
const tabs = [
  {
    link: "/",
    label: "首页",
  },
  {
    link: "/product",
    label: "产品介绍",
  },
  {
    link: "/platform",
    label: "数字化平台",
  },
  {
    link: "/customization",
    label: "定制化服务",
  },
  {
    link: "/contact",
    label: "联系我们",
  },
];
const handleTabClick = (tab: string) => {
  currentTab.value = tab;
};
</script>
  
  <style scoped lang="scss">
.tab-bar {
  width: 100%;
  height: 100%;
  display: flex;
  bottom: 0;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #ffffff;
  /* padding: 20px; */
  font-size: 16px;
  font-weight: 500;
  font-family: Source Han Sans CN, Source Han Sans CN;
  color: #333333;
}

.tab-bar a {
  margin: 0 30px;
  text-decoration: none;
  color: #333;
  padding-bottom: 15px;
}

.tab-bar a:hover {
  color: #056ae5;
}
.tab-bar .active {
  color: #056ae5;
  border-bottom: 2px solid #056ae5;
  padding-bottom: 15px;

 
}
</style>