<template>
  <div class="carousel">
    <div class="carousel-inner">
      <div
        v-for="(item, index) in carouselData"
        :key="index"
        :class="['carousel-item', { active: index === state.currentIndex }]"
      >
        <div
          :style="{ backgroundImage: `url(${item.image})` }"
          class="d-block w-100"
        ></div>
      </div>
    </div>
    <div class="carousel-indicators">
      <span
        v-for="(item, index) in carouselData"
        :key="index"
        :class="['indicator', { active: index === state.currentIndex }]"
        @click="handleIndicatorClick(index)"
      ></span>
    </div>
    <div
      class="indicator-container"
      v-for="(item, index) in carouselData"
      :key="index"
    >
      <div class="first-text" v-if="index === state.currentIndex">
        <div class="title">{{ item.describe.title }}</div>
        <div
          class="desc"
          v-for="(paragraph, paragraphIndex) in item.describe.introduction
            .split('【')
            .join('\n【')
            .split('\n')"
          :key="paragraphIndex"
        >
          <p
            v-if="paragraph.trim() !== ''"
            :class="{ paragraph: paragraphIndex !== 0 }"
          >
            {{ paragraph }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts" name="Carousel">
import {
  defineProps,
  reactive,
  onMounted,
  onBeforeUnmount,
  PropType,
} from "vue";

const props = defineProps({
  carouselData: {
    type: Array as PropType<
      {
        describe: any;
        image: string;
      }[]
    >,
    default: () => [],
    required: true,
  },
  // textStyle: {
  //   type: String,
  //   required: true,
  // },
});
const state = reactive({
  currentIndex: 0,
  startX: 0,
});
const handleIndicatorClick = (index: number) => {
  state.currentIndex = index;
};
onMounted(() => {
  const interval = 3000;
  const timer = setInterval(() => {
    const nexIndex = (state.currentIndex + 1) % props.carouselData.length;
    state.currentIndex = nexIndex;
  }, interval);

  onBeforeUnmount(() => {
    clearInterval(timer);
  });
});
</script>

<style scoped lang="scss">
.carousel {
  position: relative;
  width: 100%;
  height: 100%;
  .carousel-inner {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    .carousel-item {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
      &.active {
        opacity: 1;
      }
      .d-block {
        width: 100%;
        height: 100%;
        background-size: 100% 100%;
      }
    }
  }
  .carousel-indicators {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    z-index: 1;
    margin-top: -44px;
    .indicator {
      width: 40px;
      height: 4px;
      border-radius: 50%;
      background: #ffffff;
      margin: 0 8px;
      cursor: pointer;
      opacity: 0.5;
      border-radius: 0px 0px 0px 0px;

      &.active {
        // background: #ffffff;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
      }
    }
  }
  .indicator-container {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -436px;
    // top: 400px;
    .first-text {
      font-family: Source Han Sans CN, Source Han Sans CN;
      display: flex;
      justify-content: space-around;
      // background-color: yellow;
      flex-direction: column;
      transform: translateX(50%);
      .title {
        display: inline-block;
        font-weight: bold;
        font-size: 64px;
        color: #ffffff;
        line-height: 75px;
      }
      .desc {
        display: inline-block;
        font-weight: 500;
        font-size: 24px;
        color: #f1f1f1;
        line-height: 48px;
        margin-top: 16px;
        max-width: 936px;
      }
      .paragraph {
        margin-top: -20px;
      }
    }
    .second-text {
    }
    .third-text {
    }
  }
}
</style>
