import { createRouter, createWebHashHistory,createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/Home.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/product',
    name: 'product',
    component: () => import('../views/Product.vue')
  },
  {
    path: '/customization',
    name: 'customization',
    component: () => import('../views/Customization.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/Contact.vue')
  },
  {
    path: '/platform',
    name: 'platform',
    component: () => import('../views/Platform.vue')
  },
  
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
