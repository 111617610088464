<template>
  <div class="banner">
    <div
      class="banner-item"
      v-for="(item, index) in items"
      :key="index"
      :class="{ selected: selectedIndex === index }"
      :style="{
        width: selectedIndex === index ? '840px' : '360px',
        backgroundImage: `url(${item.image})`,
        backgroundColor: selectedIndex === index ? 'rgba(0, 0, 0, 0.6)' : '',
      }"
      @click="selectItem(index)"
    >
      <div class="title-container" v-if="selectedIndex !== index">
        <h2>{{ item.category }}</h2>
        <p>{{ item.translate }}</p>
      </div>
      <div class="current-container" v-if="selectedIndex === index">
        <div class="current-content">
          <div class="category-container">
            <div class="left">{{ item.category }}</div>
            <div class="right">{{ item.translate }}</div>
          </div>
          <div class="selectde-desc">{{ item.selected.dese }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {getCurrentInstance, ref} from "vue";
const instance = getCurrentInstance();
const baseUrl = instance?.appContext.config.globalProperties.$baseUrl;
const items = ref([
  {
    category: "蓝得鱼缸",
    translate: "Lande fish tank",
    image: baseUrl + "home/测试1.jpg",
    selected: {
      dese: "蓝得智能鱼缸是一项革命性的科技创新，为您带来前所未有的智能化、便捷化鱼池养护体验。我们致力于为消费者打造一款引领未来的高科技产品，让您享受与鱼儿互动的乐趣。",
    },
  },
  {
    category: "蓝得鱼池",
    translate: "Lande fish pond",
    image: baseUrl + "home/测试2.jpg",
    selected: {
      dese: "蓝得智能鱼池是一款以科技创新为驱动，致力于为消费者提供智能化、便捷化的鱼池养护体验的高科技产品。",
    },
  },
  {
    category: "蓝得配件",
    translate: "Lande accessories",
    image: baseUrl + "home/测试3.jpg",
    selected: {
      dese: "提升鱼缸体验，蓝得智能配件！科技创新，智能化、便捷化。与蓝得智能鱼缸完美搭配，关怀水族生物，舒适成长。体验蓝得智能配件，注入智能元素！",
    },
  },
  {
    category: "公司实际",
    translate: "Company actual situation",
    image: baseUrl + "home/测试4.jpg",
    selected: {
      dese: "蓝得智能水族，智慧引领水族新时代！创新科技，卓越养殖体验。选择蓝得，与智能共创水族新篇章！",
    },
  },
]);
const selectedIndex = ref(2);

const selectItem = (index) => {
  selectedIndex.value = index;
};
</script>

<style scoped lang="scss">
.banner {
  width: 100%;
  height: 100%;
  display: flex;
  // justify-content: flex-start;

  .banner-item {
    flex-grow: 1;
    height: 100%;
    display: flex;
    // flex-direction: column;
    color: white;
    transition: all 0.3s;
    cursor: pointer;
    background-size: 100% 100%;
    .title-container {
      // display: inline-block;
      width: 150px;
      height: auto;
      color: #ffffff;
      margin-left: 40px;
      margin-top: 500px;
      h2 {
        font-family: Source Han Sans CN, Source Han Sans CN;
        font-weight: bold;
        font-size: 36px;
        line-height: 48px;
        text-align: left;
        width: 72px;
      }
      p {
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 20px;
        line-height: 23px;
        text-align: left;
        width: 143;
      }
    }

    &.selected {
      flex-grow: 3;
      .current-container {
        width: 100%;
        height: 210px;
        background: rgba(241, 241, 241, 0.8);
        border-radius: 0px 40px 0px 0px;
        margin-top: 550px;
        display: flex;
  justify-content: center;
  align-items: center;
        .current-content {
          margin: 0 40px 0 40px;
          .category-container {
            width: 100%;
        height: auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;
            .left {
              height: 50px;
              font-family: Source Han Sans CN, Source Han Sans CN;
              font-weight: bold;
              font-size: 36px;
              color: #333333;
              line-height: 50px;
              text-align: left;
             
            }
            .right {
              font-family: PingFang SC, PingFang SC;
              font-weight: 400;
              height: 28px;
              font-size: 20px;
              color: #333333;
              text-align: right;
             
            }
          }
          .selectde-desc {
            height: 60px;
            font-family: Source Han Sans CN, Source Han Sans CN;
            font-weight: 500;
            font-size: 20px;
            color: #606163;
            text-align: left;
          }
        }
      }
    }
  }
}
</style>
