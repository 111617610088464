<template>
  <div class="wrap">
    <div class="container">
      <div class="logo-container">
        <img class="imgUrl" src="./assets/蓝得智能logo@2x.png" />
      </div>
      <div class="tabbar-container"><TabBar /></div>
    </div>
    <router-view />
  </div>
</template>
<script setup lang="ts">
import TabBar from "./components/TabBar.vue";
import { onMounted, onBeforeUnmount } from 'vue';
import autofit from 'autofit.js';
onMounted(() => {
  autofit.init({
        dh: 1080,
        dw: 1920,
        resize: true
    })


});

</script>
<style lang="scss">
input {
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: none;
  background-color: transparent;
  font-size: inherit;
}
textarea {
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: none;
  background-color: transparent;
  font-size: inherit;
  width: 100%;
  resize: none;

  border: none;
}
button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}
#app {
      width: 100%;
    height: 100%;
}
.wrap {
  width: 100%;
  height: 100%;
  overflow-y: auto;                           
}
 
.container {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 10;
  background-color: #fff;
  
  .logo-container {
    width: 182px;
    height: 48px;
    .imgUrl {
      width: 100%;
      height: 100%;
    
    }
  }
  .tabbar-container {
    height: auto;
    width: 700px;
    margin-right: 3;
    margin-right: 360px;
    margin-top: 38px;
  }
}
.global-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}
ul {
    list-style: none; 
    padding: 0; 
    margin: 0;
}

</style>